/** @jsx jsx */
import { jsx, Grid, Styled, Box } from 'theme-ui'
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"


const Collection = ({ data }) => {

  const collection = data.shopifyCollection
  return (
    <Layout>
      <Box sx={{width: "100%", maxWidth: "max", mx: "auto", my: 6}}>
        <Box sx={{px: [5,5,0]}}>
          <Styled.h1>{collection.title}</Styled.h1>
        </Box>
        <Grid columns={[ 1, 2, 3 ]}>
          {collection.products.map((product,i) => {
            return (
              <Styled.a as={Link} to={"/products/" + product.handle} key={i} key={i}>
                <BackgroundImage 
                  fluid={product.images[0].localFile.childImageSharp.fluid}
                  backgroundColor={`#000`}
                  style={{height: "400px", width: "100%", backgroundPosition: "top center", backgroundSize: "cover"}}>
                </BackgroundImage>
                <Box sx={{textAlign: 'center'}}>
                  <Styled.h4>{product.title}</Styled.h4>
                </Box>
              </Styled.a>
            )
          })}
        </Grid>
      </Box>
    </Layout>
  )
}

export default Collection

export const pageQuery = graphql`
query AllCollections($shopifyId: String!) {
  shopifyCollection(shopifyId: {eq: $shopifyId}) {
    title
    description
    handle
    shopifyId
    products {
      title
      description
      handle
      productType
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      images {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
}
`